export interface ProductLabelData {
    label_id?: number | string | null;
    name?: string | null;
    type?: string | null;
    position?: string | null;
    display_type?: string | null;
    img_file?: string | null;
    shape?: string | null;
    customize_css_container_large?: string | null;
    customize_css_label_large?: string | null;
    customize_css_container_medium?: string | null;
    customize_css_label_medium?: string | null;
    customize_css_container_small?: string | null;
    customize_css_label_small?: string | null;
}

export function cleanLabelData<T extends ProductLabelData>(labels: (T | null)[] | null): ProductLabelData[] {
    if (!labels) {
        return [];
    }

    return labels.map((label) => {
        return {
            label_id: label?.label_id,
            name: label?.name,
            type: label?.type,
            position: label?.position,
            display_type: label?.display_type,
            img_file: label?.img_file,
            shape: label?.shape,
            customize_css_container_large: label?.customize_css_container_large,
            customize_css_label_large: label?.customize_css_label_large,
            customize_css_container_medium: label?.customize_css_container_medium,
            customize_css_label_medium: label?.customize_css_label_medium,
            customize_css_container_small: label?.customize_css_container_small,
            customize_css_label_small: label?.customize_css_label_small,
        };
    });
}
